

.createplaybtn:focus{
    background-color: #ff9900;
    color: white;
}
.createplaybtn::before{
    background-color: #ff9900;
    color: white;
}



