

.switch {
    position: relative;
    display: inline-block;
    width: 21%;
    height: 37px;
    border-radius: 25px;
    border: 1px solid #eee;
  }
  .switch input { 
    opacity: 0;
    width: 10%;
    height: 37px;
    border-radius: 10px;

  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 25px;
    content-visibility: 'erw';
  }
  .slider:after{
    position:absolute;
    content: 'Annually';
    color: gray;
    font-weight: 400;
    font-size: 15px;
    padding-left: 30px;
    padding-top: 6px;
  }
  
  .slider:before {
    position: absolute;
    content: "Monthly";
    font-weight: 400;
    color: white;
    font-size: 15px;
    padding-top: 6px;
    width: 50%;
    height: 37px;
    left: 0px;
    bottom: 0px;
    place-content: 'Manually';
    background-color: blue;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 25px;
  } 
  input:checked + .slider {
    background-color: #2196F3;
    content: 'Anually';
    color: white;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(110px);
    -ms-transform: translateX(110px);
    transform: translateX(110px);
  }

  .css-1gjgmky-MuiToggleButtonGroup-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: 4px;
    /* height: auto; */
    width: auto;
    
}
.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: white;
  background-color: rgb(0,0,255);
  padding-left: 20px;
  padding-right: 19px;
  padding-top: none;
  padding-bottom: none;
}

.css-d9c359-MuiButtonBase-root-MuiToggleButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin-left: -8px !important;
  margin-right: -8px !important;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: capitalize !important;
  border-radius: 4px;
  padding-left: 27px !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-right: 31px !important;
}

.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
  margin-left: auto;
  border-left: 1px solid transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
  /* .togglebuttongroup{
    border-radius: 25px;
  }

  .togglebutton{
    border-radius: 25px;
    background-color: #2196F3;
}
  .togglebutton:focus{
      background-color: yellow;
  } */

  .backimg {
    width: 100%;
    height: auto;
    background-image: url('../../../public/assets/images/backpng1.jpg');
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}
