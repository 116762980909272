.nav-pills li .dashboardmenulink:hover{
    background-color: #ff9900;
    color: 'white';
}
.nav-pills li button:hover{
    background-color: #ff9900;
    color: 'white';
}
.nav-pills li button:focus{
    background-color: #ff9900;
    color: 'white';
}
.nav-pills li .dashboardmenulink:focus{
    background-color: #ff9900;
    color: 'white';
}
/* .menu-item {
    height: 100vh;
} */
.nav-pills {
    margin-left: none;
}
.nav-pills li .menuitemfont{
    font-size: 14px;
    padding: 0px !important;
}

.upgradbottom {
    width: 100%;
}
.menu-item{
    min-height: 100vh;
    top: 0;
    left: 0;
    font-size: 22px;
    padding: 0px !important;
}
.menu-item a:focus{
    background-color: #ff9900;
    color: white;
}
@media only screen and (max-width: 578px) {
    .nav-Link {
      text-align:center;
    }
    .menu-item {
        text-align: center;
        width: 46px;
        justify-content: center;
    }
    .t-c-transpLogo {
        max-width: 50px;
        height: 30px;
    }
    .dashboardmenulink {
        text-align: center;
        justify-content: center;
    }
    
  }
  /* .rotate-icon{
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }
  .rotate-icon:hover{
    animation: rotateIcon 0.3s ease-in-out;
  } */

  

  





