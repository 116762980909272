.page-container {
    margin: 20px;
  }
  
  h1 {
    margin-bottom: 10px;
  }
  
  .payment-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .payment-table th,
  .payment-table td {
    padding: 8px;
    border: 1px solid #ccc;
  }
  
  .payment-table th {
    background-color: #f1f1f1;
  }
  
  .payment-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .pagination{

  }

.paginationcls  {
  display: flex;
  flex-direction: row-reverse;
}
.paginationcls a {
  color: gray;
  text-decoration: underline;
  border: 1px solid gray;
  padding: 6px 15px;

  font-size: 18px;
  text-decoration: none;
  align-items: center !important;
  border-radius: 3px;
}
.paginationcls a:hover {
  background-color: black;
  color: white;
}
.paginationcls .mypricecardlist ul, li {
  padding-top: 10px;
  padding-bottom: 3px;
  text-align: left;
  justify-content: left;
  margin-left: 2px;
}