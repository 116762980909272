

hr.hr-text {
    position: relative;
      border: none;
      height: 1px;
      background: #999;
  }
  
  hr.hr-text::before {
      content: attr(data-content);
      display: inline-block;
      background: #fff;
      font-weight: bold;
      font-size: 0.85rem;
      color: #999;
      border-radius: 30rem;
      padding: 0.2rem 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  @media only screen and (max-width: 600px){
    .formOfSignin {
        width: 100% !important;
        min-width: initial;
    }
    }