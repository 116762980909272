

.signbgimg{
    width: 100%;
    height: auto;
    background-image: url('../../../public/assets/images/falldown.jpg');
    position: relative;
    background-repeat: no-repeat;
    background-size:contain;
    margin-left: auto;
    margin-right: auto;
}
.error{
    color: red;
    font-size: 12px;
}

@media only screen and (max-width: 600px){
.formOfRegister {
    width: 100% !important;
    min-width: initial !important;
}
}
