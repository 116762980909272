
.testimonial-container {
    border: 2px solid #ccc;
    background-color: #eee;
    border-radius: 5px;
    padding: 16px;
    margin: 16px 0
  }
  
  .testimonial-container::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .testimonial-container img {
    float: left;
    margin-right: 20px;
    border-radius: 50%;
  }
  
  .testimonial-container span {
    font-size: 20px;
    margin-right: 15px;
  }
  
  @media (max-width: 500px) {
    .testimonial-container {
        text-align: center;
    }
    .testimonial-container img {
        margin: auto;
        float: none;
        display: block;
    }
  }
  