

@media screen and (max-width: 480px) {
    body {
      background-color: lightgreen;
    }
  }

  .float{
    position:fixed;
    width:70px;
    height:50px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:5px;
    text-align:center;
    font-size:30px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
  }
  
  .my-float{
    margin-top:0px;
  }

  .Link:hover {
    color: none !important;
}